import React from "react"

type Option = {
  label: string
  value: string | number
}

type ButtonGroupProps = {
  options: Option[]
  onClick: (value: Option["value"]) => void
  selectedValue?: Option["value"]
  variant?: string
  size?: "sm" | "lg"
}

const ButtonGroup = ({
  options,
  onClick,
  selectedValue,
  variant = "primary",
  size,
}: ButtonGroupProps) => {
  const handleClick = (value: Option["value"]) => {
    onClick(value)
  }

  const sizeClass = size ? `btn-group-${size}` : ""

  return (
    <div className={`btn-group ${sizeClass}`} role="group" aria-label="Button group">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          onClick={() => handleClick(option.value)}
          className={`
            btn
            ${selectedValue === option.value ? `btn-${variant}` : `btn-outline-${variant}`}
          `}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}

export default ButtonGroup
