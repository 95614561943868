import React, { useState, useEffect } from "react"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikTextarea from "~/library/FormikTextarea"

import { apiNotes } from "~/routes"
import getAppConfig from "~/utils/getAppConfig"

interface Note {
  id: string
  body: string
  createdAt: string
  createdBy: string
}

interface ResourceNotesProps {
  resourceType: string
  resourceId: string
  apiEndpoint?: string
}

const noteSchema = Yup.object({
  body: Yup.string().required("Note content is required"),
})

const ResourceNotes: React.FC<ResourceNotesProps> = ({ resourceType, resourceId }) => {
  const [notes, setNotes] = useState<Note[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { venueSlug } = getAppConfig()

  const apiEndpoint = apiNotes.index.path({ venueSlug, resourceType, resourceId })

  useEffect(() => {
    if (!resourceId) return

    fetchNotes()
  }, [resourceType, resourceId])

  const fetchNotes = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const response = await fetch(apiEndpoint)
      if (!response.ok) throw new Error("Failed to fetch notes")
      const data = await response.json()
      setNotes(data.notes)
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred")
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (formValues, { resetForm }) => {
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          note: formValues,
        }),
      })

      if (!response.ok) throw new Error("Failed to create note")

      const createdNote = await response.json()
      resetForm()
      setNotes((prevNotes) => [...prevNotes, createdNote.note])
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to create note")
    }
  }

  return (
    <div>
      {/* Notes List */}
      <div className="list-group mb-4">
        {notes.map((note) => (
          <div key={note.id} className="list-group-item">
            <div className="d-flex w-100 justify-content-between">
              <small className="text-muted">{new Date(note.createdAt).toLocaleString()}</small>
              <small className="text-muted">{note.createdBy?.name}</small>
            </div>
            <p className="mb-1">{note.body}</p>
          </div>
        ))}

        {!isLoading && notes.length === 0 && (
          <div className="text-center text-muted mt-3">No notes yet</div>
        )}
      </div>

      {/* Note Form */}
      <FormikWrapper
        onSubmit={handleSubmit}
        initialValues={{
          body: "",
        }}
        validationSchema={noteSchema}
      >
        <FormikTextarea label="Note Content" name="body" placeholder="Add New Note" />
        <div className="d-flex">
          <input type="reset" className="btn btn-outline-secondary ms-auto me-2" value="Cancel" />
          <button type="submit" className="btn btn-primary">
            Add Note
          </button>
        </div>
      </FormikWrapper>

      {/* Error Message */}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      {/* Loading State */}
      {isLoading && (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResourceNotes
