// JsFromRoutes CacheKey 4c5e2eea96d422a04bdc4bd8b416654e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  search: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/clients/search"),
  index: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/clients"),
  show: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/clients/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/api/:venue_slug/clients/:id"),
}
