// JsFromRoutes CacheKey d75a075c5e10aa61cbe2036f768348a9
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/api/:venue_slug/bookings/:booking_id/payments"
  ),
  destroy: /* #__PURE__ */ definePathHelper(
    "delete",
    "/api/:venue_slug/bookings/:booking_id/payments/:id"
  ),
}
