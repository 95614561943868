import React from "react"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import moment from "moment"
import { groupBy } from "lodash"
import getAppConfig from "~/utils/getAppConfig"

const bookingsToday = []
const bookingsTomorrow = []

const groupedBookings = groupBy(bookingsTomorrow, "salesRep")

const Dashboard = () => {
  const { venue } = getAppConfig()
  const activityIcons = {
    payment: "ri-money-dollar-circle-line",
    sale: "ri-book-line",
  }
  const formatCurrency = (currency: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }
  return (
    <div className="w-full h-full p-3">
      <h1 className="text-2xl">{venue.name}</h1>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-2">Today&apos;s Bookings</h4>
              <table className="table table-centered table-nowrap mb-0 table-sm">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Client Name</th>
                    <th>Area</th>
                    <th>Rep</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingsToday.map((booking, i) => (
                    <tr key={i}>
                      <td>
                        <Link to={`/bookings/ea100d14-9a79-4305-90b2-638c560b4dd7`}>
                          {booking.bookingName}
                        </Link>
                      </td>
                      <td>
                        <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                          <img
                            src={`https://avatar.iran.liara.run/username?username=${booking.clientName
                              .split(" ")
                              .join("+")}`}
                            className="rounded-circle avatar-xs me-2"
                          />
                          {booking.clientName}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/area/ea100d14-9a79-4305-90b2-638c560b4dd7`}>
                          {booking.area}
                        </Link>
                      </td>
                      <td>
                        <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                          <img
                            src={`https://avatar.iran.liara.run/username?username=${booking.salesRep
                              .split(" ")
                              .join("+")}`}
                            className="rounded-circle avatar-xs me-2"
                          />
                          {booking.salesRep}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/bookings/ea100d14-9a79-4305-90b2-638c560b4dd7`}>
                          <Button variant="primary" size="sm">
                            View Booking
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Tomorrow&apos;s Bookings</h4>
              {Object.keys(groupedBookings)
                .sort()
                .map((salesRep, i) => {
                  return (
                    <div className="mt-3" key={i}>
                      <h4 className="border-bottom">{salesRep}</h4>
                      {groupedBookings[salesRep].map((booking, i) => {
                        return (
                          <div key={salesRep + i}>
                            <div className="d-flex align-items-center">
                              <Link to="#">
                                <p className="m-0 fw-bold">{booking.bookingName}</p>
                              </Link>
                              <p className="mb-0 ms-auto">
                                Balance remaining: {formatCurrency(booking.balanceRemaining)}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Recent Activity</h4>
              {[].map((activity, i) => {
                return (
                  <div className="d-flex align-items-center mb-1" key={i}>
                    <i className={activityIcons[activity.type]} />
                    <span className="ms-2">
                      {activity.text} by {activity.user}{" "}
                      {moment()
                        .subtract(i * 2.7, "hours")
                        .fromNow()}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
