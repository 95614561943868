import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiClients } from "~/routes"

import PageTitle from "~/library/PageTitle"
import LoadingWrapper from "~/library/LoadingWrapper"
import CatchCard from "~/library/CatchCard"
import Pagination from "~/library/Pagination"
import CatchTable from "~/library/CatchTable"

const ClientList = () => {
  const { venueSlug } = getAppConfig()
  const [page, setPage] = useState(1)

  const {
    state: clients,
    refetch,
    metadata,
    isLoading,
  } = useFetchResource(apiClients.index.path({ venueSlug }), "clients", {})

  useEffect(() => {
    refetch({ page })
  }, [page])

  return (
    <LoadingWrapper isLoading={isLoading}>
      <PageTitle title="Clients" />
      <CatchCard>
        <CatchTable
          data={clients}
          emptyMessage="No Clients"
          columns={[
            {
              key: "name",
              header: "Name",
              formatter: (name, data) => <Link to={`/clients/${data.id}`}>{name}</Link>,
            },
            {
              key: "email",
              header: "Email",
            },
            {
              key: "phone",
              header: "Phone",
            },
          ]}
        />
        <Pagination metadata={metadata} onPageChange={setPage} />
      </CatchCard>
    </LoadingWrapper>
  )
}

export default ClientList
