// JsFromRoutes CacheKey a874d3e28659f93dfc6babcb18d628e1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  archive: /* #__PURE__ */ definePathHelper("post", "/api/:venue_slug/bookings/:id/archive"),
  index: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/bookings"),
  create: /* #__PURE__ */ definePathHelper("post", "/api/:venue_slug/bookings"),
  show: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/bookings/:id"),
  update: /* #__PURE__ */ definePathHelper("patch", "/api/:venue_slug/bookings/:id"),
}
