import React, { useState, useCallback, useEffect, useMemo } from "react"
import { Form } from "react-bootstrap"
import { useField, useFormikContext } from "formik"
import AsyncSelect from "react-select/async"
import { debounce } from "lodash"
import { singularize } from "~/utils/helpers"

interface SelectOrCreateProps<T> {
  name: string
  attributesPrefix?: string
  endpoint: string
  searchParam?: string
  responseKey?: string
  placeholder?: string
  allowCreate?: boolean
  createFields: Array<{
    name: keyof T
    label: string
    type: "text" | "number" | "email"
    required?: boolean
  }>
  newItemValidator?: (item: Partial<T>) => boolean
  onError?: (error: string) => void
  labelKey?: keyof T
  valueSelector?: (item: ApiResponse<T>) => any
}

interface ApiResponse {
  id: string
  [key: string]: any
}

// Individual field component
function CreateField<T>({
  field,
  attributesPrefix,
}: {
  field: SelectOrCreateProps<T>["createFields"][0]
  attributesPrefix: string
}) {
  const fieldName = `${attributesPrefix}.${String(field.name)}`
  const [nestedField, nestedMeta] = useField(fieldName)

  return (
    <Form.Group className="mb-3">
      <Form.Label>{field.label}</Form.Label>
      <Form.Control
        {...nestedField}
        type={field.type}
        required={field.required}
        isInvalid={nestedMeta.touched && nestedMeta.error}
      />
      {nestedMeta.touched && nestedMeta.error && (
        <Form.Control.Feedback type="invalid">{nestedMeta.error}</Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

// Fields container component
function CreateFields<T>({
  attributesPrefix,
  createFields,
}: {
  attributesPrefix: string
  createFields: SelectOrCreateProps<T>["createFields"]
}) {
  return (
    <>
      {createFields.map((field) => (
        <CreateField key={String(field.name)} field={field} attributesPrefix={attributesPrefix} />
      ))}
    </>
  )
}

export function SelectOrCreate<T>({
  name,
  attributesPrefix,
  endpoint,
  searchParam = "search",
  responseKey,
  placeholder = "Select or start typing to create...",
  allowCreate = true,
  createFields,
  onError,
  labelKey = "name" as keyof T,
  valueSelector = (item: ApiResponse<T>) => item.id,
}: SelectOrCreateProps<T>) {
  const [field, meta, helpers] = useField(name)
  const [isLoadingValue, setIsLoadingValue] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const { setFieldValue } = useFormikContext()

  const createOption = useCallback(
    (inputValue: string) => {
      if (!allowCreate) return null
      return {
        value: "create-new",
        label: inputValue ? `+ Create "${inputValue}"` : "+ Create New",
        inputValue,
      }
    },
    [allowCreate]
  )

  useEffect(() => {
    const loadInitialValue = async () => {
      if (field.value) {
        const option = await loadValue(field.value)
        setSelectedOption(option)
      } else {
        setSelectedOption(null)
      }
    }
    loadInitialValue()
  }, [field.value])

  const debouncedSearch = useMemo(
    () =>
      debounce(async (inputValue: string, callback: (options: any[]) => void) => {
        try {
          const createOpt = createOption(inputValue)

          if (!inputValue || inputValue.length < 2) {
            callback(createOpt ? [createOpt] : [])
            return
          }

          const queryObject = { [searchParam]: inputValue }
          const queryString = `q=${encodeURIComponent(JSON.stringify(queryObject))}`

          const response = await fetch(`${endpoint}?${queryString}`)
          if (!response.ok) throw new Error("Failed to fetch items")
          const responseData = await response.json()
          const data: ApiResponse<T>[] = responseKey ? responseData[responseKey] : responseData

          const options = data.map((item) => ({
            value: valueSelector(item),
            label: String(item[labelKey]),
          }))

          callback(createOpt ? [...options, createOpt] : options)
        } catch (error) {
          onError?.(error instanceof Error ? error.message : "Failed to fetch items")
          const createOpt = createOption(inputValue)
          callback(createOpt ? [createOpt] : [])
        }
      }, 300),
    [endpoint, searchParam, responseKey, labelKey, valueSelector, createOption]
  )

  useEffect(() => debouncedSearch.cancel, [debouncedSearch])

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    debouncedSearch(inputValue, callback)
  }

  const handleSelect = (option: any) => {
    if (!option) {
      setIsCreating(false)
      helpers.setValue(null)
      setSelectedOption(null)
      if (attributesPrefix) {
        createFields.forEach((field) =>
          setFieldValue(`${attributesPrefix}.${String(field.name)}`, "")
        )
      }
      return
    }

    if (option.value === "create-new" && allowCreate) {
      setIsCreating(true)
      helpers.setValue(null)
      setSelectedOption(null)
      if (attributesPrefix) {
        createFields.forEach((field) => {
          const value = field.name === "name" && option.inputValue ? option.inputValue : ""
          setFieldValue(`${attributesPrefix}.${String(field.name)}`, value)
        })
      }
    } else {
      setIsCreating(false)
      helpers.setValue(option.value)
      setSelectedOption(option)
      if (attributesPrefix) {
        createFields.forEach((field) =>
          setFieldValue(`${attributesPrefix}.${String(field.name)}`, "")
        )
      }
    }
  }

  const handleCancel = () => {
    setIsCreating(false)
    helpers.setValue(null)
    setSelectedOption(null)
    if (attributesPrefix) {
      createFields.forEach((field) =>
        setFieldValue(`${attributesPrefix}.${String(field.name)}`, "")
      )
    }
  }

  const loadValue = async (value: string) => {
    try {
      setIsLoadingValue(true)
      const response = await fetch(`${endpoint}/${value}`)
      if (!response.ok) return null
      const responseData = await response.json()
      const item: ApiResponse<T> = responseKey
        ? responseData[singularize(responseKey)]
        : responseData

      return {
        value: valueSelector(item),
        label: String(item[labelKey]),
      }
    } catch (error) {
      onError?.(error instanceof Error ? error.message : "Failed to fetch item")
      return null
    } finally {
      setIsLoadingValue(false)
    }
  }

  return (
    <div className="select-or-create">
      <AsyncSelect
        value={selectedOption}
        loadOptions={loadOptions}
        onChange={handleSelect}
        placeholder={allowCreate ? placeholder : "Select an existing item..."}
        isClearable
        cacheOptions
        defaultOptions={allowCreate ? [createOption("")].filter(Boolean) : true}
        noOptionsMessage={() => "No results found"}
        isLoading={isLoadingValue}
        loadingMessage={() => "Loading..."}
        className={meta.touched && meta.error ? "is-invalid" : ""}
      />
      {meta.touched && meta.error && <div className="invalid-feedback d-block">{meta.error}</div>}

      {isCreating && allowCreate && attributesPrefix && (
        <div className="create-fields border rounded p-3 mb-3 bg-light">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="mb-0">Create New</h6>
            <button
              type="button"
              className="btn btn-link text-secondary p-0"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
          <CreateFields<T> attributesPrefix={attributesPrefix} createFields={createFields} />
        </div>
      )}
    </div>
  )
}

export default SelectOrCreate
