import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import moment from "moment"

import { apiClients } from "~/routes"
import { useToast } from "~/utils/Toast"

import ResourceNotes from "~/library/ResourceNotes"
import CatchCard from "~/library/CatchCard"
import CatchTable from "~/library/CatchTable"
import PageTitle from "~/library/PageTitle"
import LoadingWrapper from "~/library/LoadingWrapper"
import CatchDropdown from "~/library/CatchDropdown"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"

import UpdateClientModal from "./UpdateClientModal"

const ClientView = () => {
  const { venueSlug } = getAppConfig()
  const toast = useToast()
  const { id } = useParams()
  const endpoint = apiClients.show.path({ venueSlug, id })
  const { state: client, isLoading, refetch } = useFetchResource(endpoint, "client", {})

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  const updateClient = (values) => {
    const req = apiClients.update({ data: { venueSlug, id, client: values } })
    req.then(() => {
      toast("Client Updated", "success")
      refetch()
    })
    req.catch((res) => {
      toast(`Error updating Booking. ${res.body.errors.join(", ")}`, "danger")
    })
  }

  const renderDetail = (title, content, size = 6) => {
    return (
      <div className={`col-${size || 6}`}>
        <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">{title}</p>
        <div className="d-flex">
          <div className="mt-1 font-14 mb-2">{content}</div>
        </div>
      </div>
    )
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <PageTitle title="Client Details">
        <CatchDropdown
          label="Actions"
          className="ms-2"
          items={[
            {
              label: "Edit Client",
              onClick: () => setIsUpdateModalOpen(true),
            },
          ]}
        />
      </PageTitle>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-2">{client.name}</h4>
              <div className="row">
                {renderDetail("Email", client.email)}
                {renderDetail("Phone", client.phone)}
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-body">
              <h4 className="card-title mb-2">Bookings</h4>
              <CatchTable
                data={client.bookings}
                emptyMessage="No Bookings"
                columns={[
                  {
                    key: "name",
                    header: "Booking",
                    formatter: (name, data) => <Link to={`/bookings/${data.id}`}>{name}</Link>,
                  },
                  {
                    key: "date",
                    header: "Date",
                    formatter: (date) => moment(date).format("MMM Do YYYY"),
                  },
                  {
                    key: "area",
                    header: "Area",
                    formatter: (area) => <Link to={`/areas/${area.id}`}>{area.name}</Link>,
                  },
                  {
                    key: "anticipatedRevenueCents",
                    header: "Anticipated Revenue",
                    formatter: (antRev) => formatCurrency(antRev),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <CatchCard title="Notes">
            <ResourceNotes resourceType="client" resourceId={client?.id} />
          </CatchCard>
        </div>
      </div>
      <UpdateClientModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        onSubmit={updateClient}
        client={client}
      />
    </LoadingWrapper>
  )
}

export default ClientView
