import React from "react"

import CatchModal from "~/library/CatchModal"

import getAppConfig from "~/utils/getAppConfig"
import { apiPayments } from "~/routes"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikInput from "~/library/FormikInput"
import FormikSelect from "~/library/FormikSelect"
import CurrencyInput from "~/library/CurrencyInput"

const paymentSchema = Yup.object({
  amountCents: Yup.number()
    .required("An amount is required")
    .min(1, "Amount must be greater than 0"),
  paymentMethod: Yup.string().required("Select a Payment Method"),
  source: Yup.string().required("Select a Source"),
  capturedOn: Yup.string().required("Select a date"),
})

const initialValues = {
  amountCents: 0,
  paymentMethod: "",
  source: "",
  capturedOn: "",
}

const PaymentModal = ({ onClose, onSuccess, bookingId }) => {
  const { venueSlug } = getAppConfig()
  const handleSubmit = (values) => {
    const req = apiPayments.create({
      data: {
        venueSlug,
        bookingId: bookingId,
        payment: values,
      },
    })
    req.then(() => {
      onSuccess()
      onClose()
    })
  }

  return (
    <CatchModal isOpen={true} onClose={onClose} title={"New Payment"}>
      <FormikWrapper
        initialValues={initialValues}
        validationSchema={paymentSchema}
        onSubmit={handleSubmit}
      >
        <div className="space-y-4">
          <CurrencyInput name="amountCents" label="Amount" required />
          <FormikInput name="capturedOn" label="Captured On" type="date" placeholder="" required />
          <FormikSelect
            options={[
              { label: "Cash", value: "cash" },
              { label: "Check", value: "check" },
              { label: "ACH", value: "ach" },
              { label: "Wire", value: "wire" },
              { label: "Credit Card", value: "credit_card" },
              { label: "Comp", value: "comp" },
              { label: "Other", value: "other" },
            ]}
            label="Payment Method"
            name="paymentMethod"
            placeholder="Select Payment Method"
          />
          <FormikInput
            name="source"
            label="Payment Source"
            placeholder="Where was this payment captured?"
            required
          />
          <div className="d-flex gap-2">
            <button type="button" onClick={onClose} className="btn btn-outline-secondary ms-auto">
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Create Payment
            </button>
          </div>
        </div>
      </FormikWrapper>
    </CatchModal>
  )
}
export default PaymentModal
