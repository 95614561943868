import React from "react"
import CatchModal from "~/library/CatchModal"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikInput from "~/library/FormikInput"

const clientSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().nullable(),
})

const UpdateClientModal = ({ isOpen, client, onSubmit, onClose }) => {
  const initialValues = {
    name: client?.name || "",
    email: client?.email || "",
    phone: client?.phone || "",
  }

  const handleSubmit = (values, { setSubmitting }) => {
    onSubmit(values)
    setSubmitting(false)
    onClose()
  }

  return (
    <CatchModal isOpen={isOpen} onClose={onClose} title="Update Client">
      <FormikWrapper
        initialValues={initialValues}
        validationSchema={clientSchema}
        onSubmit={handleSubmit}
      >
        <FormikInput name="name" label="Name" placeholder="Enter client name" required />

        <FormikInput
          name="email"
          label="Email"
          type="email"
          placeholder="Enter client email"
          required
        />

        <FormikInput name="phone" label="Phone" placeholder="Enter client phone" />

        <div className="d-flex mt-4">
          <button type="button" className="btn btn-outline-secondary ms-auto" onClick={onClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary ms-2">
            Update Client
          </button>
        </div>
      </FormikWrapper>
    </CatchModal>
  )
}

export default UpdateClientModal
