import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Ellipsis, TrashIcon } from "lucide-react"
import { startCase } from "lodash"
import moment from "moment"
import { useToast } from "~/utils/Toast"

import { formatCurrency } from "~/utils/helpers"
import LineItem from "~/types/LineItem"

import PageTitle from "~/library/PageTitle"
import LoadingWrapper from "~/library/LoadingWrapper"
import CatchCard from "~/library/CatchCard"
import CatchDropdown from "~/library/CatchDropdown"
import CatchTable from "~/library/CatchTable"
import ResourceNotes from "~/library/ResourceNotes"
import ConfirmationModal from "~/library/ConfirmationModal"

import LineItemModal from "./LineItemModal"
import PaymentModal from "./PaymentModal"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiBookings, apiLineItems, apiPayments } from "~/routes"

const BookingView = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)

  const [isLineItemModalOpen, setIsLineItemModalOpen] = useState(false)
  const [editingLineItem, setEditingLineItem] = useState(null)
  const [deletingLineItem, setDeletingLineItem] = useState(null)
  const [deletingPayment, setDeletingPayment] = useState(null)

  const { venueSlug } = getAppConfig()
  const { id } = useParams()
  const endpoint = apiBookings.show.path({ venueSlug, id })
  const { state: booking, refetch, isLoading } = useFetchResource(endpoint, "booking", {})
  const toast = useToast()

  const renderDetail = (title, content, size = 6) => {
    return (
      <div className={`col-${size || 6}`}>
        <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">{title}</p>
        <div className="d-flex">
          <div className="mt-1 font-14 mb-2">{content}</div>
        </div>
      </div>
    )
  }

  const toggleBookingArchived = () => {
    const req = apiBookings.archive({ venueSlug, id })
    req.then(refetch)
  }

  const closeLineItemModal = () => {
    setIsLineItemModalOpen(false)
    setEditingLineItem(null)
  }

  const updateLineItems = () => {
    if (editingLineItem) {
      setEditingLineItem(null)
      toast("Line Item Updated", "success")
    } else {
      toast("Line Item Created", "success")
    }
    setIsLineItemModalOpen(false)
    refetch()
  }

  const editLineItem = (lineItem) => {
    setEditingLineItem(lineItem)
    setIsLineItemModalOpen(true)
  }

  const deletePayment = () => {
    const req = apiPayments.destroy({
      venueSlug,
      id: deletingPayment,
      bookingId: booking.id,
    })
    req.then(() => {
      setDeletingPayment(null)
      toast("Payment Removed", "success")
      refetch()
    })
  }

  const deleteLineItem = () => {
    const req = apiLineItems.destroy({
      data: {
        venueSlug,
        resourceId: booking.id,
        resourceType: "booking",
        id: deletingLineItem,
      },
    })
    req.then(() => {
      setDeletingLineItem(null)
      toast("Line Item Removed", "success")
      refetch()
    })
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <PageTitle title={`${booking.name}${booking.archivedAt ? " (Archived)" : ""}`}>
        <>
          {/*
          <a href="#" className="btn btn-primary">
            Print Booking Details
          </a>
          */}
          <CatchDropdown
            label="Actions"
            className="ms-2"
            items={[
              {
                label: "Edit Booking",
                to: `/bookings/${booking?.id}/edit`,
              },
              {
                label: booking.archivedAt ? "Unarchive" : "Archive",
                onClick: toggleBookingArchived,
              },
            ]}
          />
        </>
      </PageTitle>

      <div className="row">
        <div className="col-8">
          <CatchCard title="Booking Details">
            <div className="row">
              {renderDetail("Booking Name", booking.name, 4)}
              {renderDetail(
                "Booked Area",
                <Link to={`/areas/${booking.area?.id}`}>{booking.area?.name}</Link>,
                4
              )}
              {renderDetail("Date", moment(booking.date).format("MMM Do YYYY"), 4)}
              <div className="row mt-2">
                {renderDetail("Additional Details", booking.details || <em>No Details</em>, 12)}
              </div>
            </div>
          </CatchCard>
          <CatchCard title="Client Details">
            <div className="row">
              {renderDetail(
                "Name",
                <Link to={`/clients/${booking.client?.id}`}>{booking.client?.name}</Link>,
                4
              )}
              {renderDetail("Phone", booking.client?.phone, 4)}
              {renderDetail("Email", booking.client?.email, 4)}
            </div>
          </CatchCard>
          {isLineItemModalOpen && (
            <LineItemModal
              resourceType="booking"
              resourceId={booking.id}
              lineItem={editingLineItem}
              onClose={closeLineItemModal}
              onComplete={updateLineItems}
            />
          )}
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-middle mb-2">
                <h4 className="">Line Items</h4>
                <span
                  className="btn btn-primary ms-auto"
                  onClick={() => setIsLineItemModalOpen(true)}
                >
                  Add Line Item
                </span>
              </div>

              <div className="row">
                <table className="table table-centered mb-0">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Subtotal</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {booking?.lineItems?.map((lineItem: LineItem, i) => {
                      return (
                        <tr key={i} className="text-center">
                          <td key="n" className="w-100 text-start">
                            {lineItem.name}
                          </td>
                          <td key="c">{startCase(lineItem.category)}</td>
                          <td key="p">{lineItem.quantity}</td>
                          <td key="q">{formatCurrency(lineItem.amount)}</td>
                          <td key="r">{formatCurrency(lineItem.subtotal)}</td>
                          <td key="e" className="text-end">
                            <CatchDropdown
                              icon={Ellipsis}
                              iconOnly
                              className="bg-white text-black border-none"
                              items={[
                                {
                                  label: "Edit Line Item",
                                  onClick: () => editLineItem(lineItem),
                                },
                                {
                                  label: "Remove Line Item",
                                  className: "text-danger",
                                  onClick: () => setDeletingLineItem(lineItem.id),
                                },
                              ]}
                            />
                          </td>
                        </tr>
                      )
                    })}
                    <tr key="total" className="bg-dark">
                      <th key="t" colSpan="4" className="text-end text-white">
                        Booking Total
                      </th>
                      <td key="ta" className="text-white text-center">
                        {formatCurrency(booking.total)}
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <h4 className="card-title mb-0">Payments</h4>
                <button
                  className="btn btn-primary ms-auto"
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  <i className="uil uil-plus me-2" />
                  New Payment
                </button>
              </div>
              <CatchTable
                data={booking.payments}
                emptyMessage="No Payments"
                columns={[
                  {
                    key: "amount",
                    header: "Amount",
                    formatter: (amount) => formatCurrency(amount),
                  },
                  {
                    key: "capturedOn",
                    header: "Captured",
                    formatter: (date) => moment(date).format("MMM Do YYYY"),
                  },
                  {
                    key: "capturedBy",
                    header: "Captured By",
                    formatter: (capt, data) => data.createdBy.name,
                  },
                  {
                    key: "paymentMethod",
                    header: "Payment Method",
                  },
                  {
                    key: "source",
                    header: "Source",
                  },
                  {
                    key: "delete",
                    header: "",
                    formatter: (_d, data) => {
                      return (
                        <TrashIcon
                          className="text-danger cursor-pointer"
                          onClick={() => setDeletingPayment(data.id)}
                        />
                      )
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <CatchCard title="Financials">
            <div className="row">
              {renderDetail("Anticipated Revenue", formatCurrency(booking.anticipatedRevenue), 12)}
              {
                // renderDetail("Payments", "TBD", 12)}
                // renderDetail("Balance Remaining", "TBD", 12)
              }
            </div>
            {
              // <div className="progress">
              //   <div
              //     className="progress-bar bg-success"
              //     style={{ width: `${(booking.payments / booking.antRev) * 100}%` }}
              //   />
              // </div>
            }
          </CatchCard>
          <CatchCard title="Internal Details">
            <div className="row">
              {booking.archivedAt &&
                renderDetail("Archived", moment(booking.archivedAt).fromNow(), 12)}
              {renderDetail("Created by", booking.createdBy?.name, 12)}
              {renderDetail(
                "Created on",
                moment(booking.createdAt).format("MMM Do YYYY hh:mma"),
                12
              )}
            </div>
          </CatchCard>
          <CatchCard title="Notes">
            <ResourceNotes resourceType="booking" resourceId={booking.id} />
          </CatchCard>
        </div>
      </div>
      <ConfirmationModal
        isOpen={deletingLineItem}
        onClose={() => setDeletingLineItem(null)}
        onConfirm={deleteLineItem}
        title="Remove Line Item?"
      />
      <ConfirmationModal
        isOpen={deletingPayment}
        onClose={() => setDeletingPayment(null)}
        onConfirm={deletePayment}
        title="Delete Payment?"
      />
      {isPaymentModalOpen && (
        <PaymentModal
          onClose={() => setIsPaymentModalOpen(false)}
          onSuccess={refetch}
          bookingId={booking.id}
        />
      )}
    </LoadingWrapper>
  )
}

export default BookingView
